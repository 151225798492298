const locations = [
    { id: 1, name: 'Selkup', x: 616, y: 116, rarity: 'Epic' },
    { id: 2, name: 'Korea', x: 845, y: 165, rarity: 'Rare' },
    { id: 3, name: 'Kurdistan', x: 510, y: 252, rarity: 'Rare' },
    { id: 4, name: 'Turkic people', x: 630, y: 200, rarity: 'Common' },
    { id: 5, name: 'Dravidian people', x: 672, y: 272, rarity: 'Common' },
    { id: 6, name: 'Lepcha', x: 683, y: 221, rarity: 'Common' },
    { id: 7, name: 'China', x: 802, y: 214, rarity: 'Common' },
    { id: 8, name: 'Cambodia', x: 745, y: 252, rarity: 'Common' },
    { id: 9, name: 'Japan', x: 883, y: 175, rarity: 'Common' },
    { id: 10, name: 'Iran', x: 577, y: 235, rarity: 'Common' },
    { id: 11, name: 'Hawaii', x: 924, y: 222, rarity: 'Epic' },
    { id: 12, name: 'Papua', x: 865, y: 332, rarity: 'Rare' },
    { id: 13, name: 'Yalarrnga', x: 702, y: 374, rarity: 'Rare' },
    { id: 14, name: 'Tahiti', x: 924, y: 291, rarity: 'Common' },
    { id: 15, name: 'Timor', x: 786, y: 320, rarity: 'Common' },
    { id: 16, name: 'Kiribati', x: 887, y: 259, rarity: 'Common' },
    { id: 17, name: 'Palawa', x: 757, y: 521, rarity: 'Common' },
    { id: 18, name: 'Salomon', x: 895, y: 340, rarity: 'Common' },
    { id: 19, name: 'Maori', x: 828, y: 485, rarity: 'Special' },
    { id: 20, name: 'Noongar', x: 592, y: 413, rarity: 'Common' },
    { id: 21, name: 'Navajo', x: 60, y: 165, rarity: 'Epic' },
    { id: 22, name: 'Emberá-Catía', x: 102, y: 315, rarity: 'Rare' },
    { id: 23, name: 'Mapuche', x: 97, y: 450, rarity: 'Rare' },
    { id: 24, name: 'British columbia', x: 56, y: 106, rarity: 'Special' },
    { id: 25, name: 'Maya', x: 110, y: 252, rarity: 'Common' },
    { id: 26, name: 'Guarani', x: 170, y: 395, rarity: 'Common' },
    { id: 27, name: 'Inuit', x: 272, y: 88, rarity: 'Common' },
    { id: 28, name: 'Náhuatl', x: 80, y: 225, rarity: 'Common' },
    { id: 29, name: 'Aymara', x: 130, y: 357, rarity: 'Common' },
    { id: 30, name: 'Yupik', x: 32, y: 47, rarity: 'Common' },
    { id: 31, name: 'Afrikaner', x: 341, y: 493, rarity: 'Special' },
    { id: 32, name: 'Mandinga', x: 266, y: 333, rarity: 'Epic' },
    { id: 33, name: 'Pigmies', x: 358, y: 382, rarity: 'Rare' },
    { id: 34, name: 'Arabic', x: 455, y: 315, rarity: 'Rare' },
    { id: 35, name: 'Zulu', x: 390, y: 499, rarity: 'Common' },
    { id: 36, name: 'Ashanti', x: 295, y: 354, rarity: 'Common' },
    { id: 37, name: 'Zanzibar', x: 440, y: 423, rarity: 'Common' },
    { id: 38, name: 'Amazigh', x: 315, y: 312, rarity: 'Common' },
    { id: 39, name: 'San', x: 375, y: 474, rarity: 'Common' },
    { id: 40, name: 'Ethiopia', x: 470, y: 360, rarity: 'Common' },
    { id: 41, name: 'Occitania', x: 340, y: 210, rarity: 'Special' },
    { id: 42, name: 'Scotland', x: 305, y: 140, rarity: 'Rare' },
    { id: 43, name: 'Galiza', x: 275, y: 213, rarity: 'Special' },
    { id: 44, name: 'Germany', x: 362, y: 180, rarity: 'Common' },
    { id: 45, name: 'Ukraine', x: 475, y: 167, rarity: 'Rare' },
    { id: 46, name: 'Albania', x: 400, y: 240, rarity: 'Common' },
    { id: 47, name: 'Iceland', x: 328, y: 86, rarity: 'Common' },
    { id: 48, name: 'Russia', x: 510, y: 115, rarity: 'Common' },
    { id: 49, name: 'Sami', x: 429, y: 60, rarity: 'Common' },
    { id: 50, name: 'Baltic sea', x: 428, y: 102, rarity: 'Epic' },
    { id: 51, name: 'Jews', x: 498, y: 277, rarity: 'Epic' },
    { id: 52, name: 'Koori', x: 762, y: 478, rarity: 'Rare' },
    { id: 53, name: 'Lakota', x: 115, y: 150, rarity: 'Common' },
    { id: 54, name: 'Hadza', x: 416, y: 404, rarity: 'Common' },
    { id: 55, name: 'Gypsy', x: 297, y: 240, rarity: 'Common' },
    { id: 56, name: 'Sama-bajau', x: 787, y: 272, rarity: 'Special' },
    { id: 57, name: 'Nepal', x: 720, y: 200, rarity: 'Rare' },
];

export default locations;

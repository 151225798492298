/**
 * Items for the navigation bar
 * @type {Array}
 * @constant
 * @dev This constant is used to render the navigation bar
 * @author Jesús Sánchez Fernández
 */
export const NAV_ITEMS = [
    {
        label: 'About',
        href: 'https://mythicalbeings.io/',
    },
    {
        label: 'How to play?',
        href: 'https://mythicalbeings.io/how-to-play.html',
    },
    {
        label: 'Blog',
        href: 'https://mythicalbeings.io/blog',
    },
    {
        label: 'Shop',
        href: 'https://shop.mythicalbeings.io/',
    },
];
